import { useRef, useState } from "react";
import search from "./../assets/icons/search.svg";
import ModalComponent from "./ModalComponent";
import { useLocation, useNavigate } from "react-router-dom";
const SearchComponent = ({ moveMap, centerElement }) => {
    const [query, setQuery] = useState("");
    const [results, setResults] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const data = JSON.parse(localStorage.getItem("navigation"));

    let neededMap = localStorage.getItem("currentMap") ? localStorage.getItem("currentMap") : "0N";

    const performSearch = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);

        const searchResults = getSearchResults(inputValue);
        setResults(searchResults);
    };

    const getSearchResults = (query) => {
        if (!data || !data.data) return;
        return {
            data: data.data.filter((result) =>
                result.name.toLowerCase().includes(query.toLowerCase())
            ),
        };
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="toggle search-wrapper position-absolute r-0 px-4">

                <ModalComponent isOpen={isModalOpen} onClose={handleCloseModal} setClass="modal search top-5">
                    <div className="form-group d-flex w-100">
                        <input
                            onChange={performSearch}
                            id="search-bar"
                            className="search-bar form-control border-feri rounded-0"
                            placeholder="Search Classroom..."
                            autoComplete="off"
                            type="text"
                        />
                    </div>
                    <div className="search-results my-1 z-top w-100">
                        {query.length > 0 && results?.data?.length > 0 && (
                            <ul className="list-group rounded-0">
                                {results.data.slice(0, 5).map((result, index) => (
                                    <li
                                        className="list-group-item list-group-item-action"
                                        onClick={() => {

                                            if (location.pathname !== '/') navigate('/');

                                            let rooms = document.querySelectorAll(".selected-classroom");
                                            let pointerTexts = document.querySelectorAll('.pointer-text');
                                            let pointers = document.querySelectorAll('.pointer');

                                            const handleMoveMap = () => { moveMap(neededMap); };

                                            rooms?.forEach(room => room.classList.remove("selected-classroom"));
                                            pointers?.forEach(element => {
                                                element.classList.remove("pointer");
                                                element.removeEventListener("click", handleMoveMap);
                                            });
                                            pointerTexts?.forEach(element => element.remove());

                                            let currentMap = localStorage.getItem("currentMap")
                                                ? localStorage.getItem("currentMap")
                                                : "0N";
                                            if (document.getElementById(result.id)) {
                                                // This is where the room is selected...
                                                const room = document.getElementById(result.id);
                                                room.classList.add("selected-classroom");
                                                // Create a new <text> element
                                                const textElement = document.createElementNS(
                                                    "http://www.w3.org/2000/svg",
                                                    "text"
                                                );
                                                textElement.id = "center-element";
                                                textElement.classList.add("pointer-text", "bg-white", "fw-bold", "fs-100");
                                                // Append the icon to the <text> element
                                                textElement.textContent = "📍";
                                                // Set the position of the text element
                                                const pathBoundingBox = room.getBBox();

                                                textElement.setAttribute("x", pathBoundingBox.x + (pathBoundingBox.width - 100) / 2);
                                                textElement.setAttribute("y", pathBoundingBox.y - 15); // Adjust the vertical position as needed

                                                // Add the text element to the SVG
                                                const svgElement = document.getElementById(currentMap);
                                                svgElement.appendChild(textElement);
                                                // Center the selected classroom in the screen.
                                                // centerElement(); // TODO: Make the calculations right.
                                            } else {
                                                neededMap = result.floor;

                                                const floorData = data.data
                                                    .filter((map) =>
                                                        map.floor
                                                            .toLowerCase()
                                                            .includes(currentMap.toLowerCase())
                                                    );

                                                const elevatorData = floorData.filter((floor) =>
                                                    floor.name
                                                        .toLowerCase()
                                                        .includes(("ELEVATOR").toLowerCase())
                                                );

                                                const stairsData = floorData.filter((floor) =>
                                                    floor.name
                                                        .toLowerCase()
                                                        .includes(("STOPNICE").toLowerCase())
                                                );

                                                const transportPoints = [...elevatorData, ...stairsData];

                                                transportPoints.forEach((point) => {
                                                    const hub = document.getElementById(point.id);
                                                    if (!hub) return;
                                                    hub.addEventListener("click", handleMoveMap);
                                                    hub.classList.add("pointer");
                                                    // Find the <path> element with id

                                                    // Create a new <text> element
                                                    const textElement = document.createElementNS(
                                                        "http://www.w3.org/2000/svg",
                                                        "text"
                                                    );
                                                    textElement.classList.add("pointer-text", "bg-white");
                                                    // Append the icon to the <text> element
                                                    textElement.textContent = currentMap[0] < neededMap[0] ?
                                                        (neededMap[0] - currentMap[0]) > 1 ? "⏫" + (neededMap[0] - currentMap[0]) : "🔼" + (neededMap[0] - currentMap[0]) :
                                                        (currentMap[0] - neededMap[0]) > 1 ? "⏬" + (currentMap[0] - neededMap[0]) : "🔽" + (currentMap[0] - neededMap[0]);
                                                    // Set the position of the text element
                                                    const pathBoundingBox = hub.getBBox();

                                                    textElement.setAttribute("x", pathBoundingBox.x + (pathBoundingBox.width - 100) / 2);
                                                    textElement.setAttribute("y", pathBoundingBox.y - 15); // Adjust the vertical position as needed

                                                    // Add the text element to the SVG
                                                    const svgElement = document.getElementById(currentMap);
                                                    svgElement.appendChild(textElement);
                                                });
                                            }
                                        }}
                                        key={index}
                                    >
                                        {result.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </ModalComponent>
                <img draggable="false"
                    className="trans-3 header-btn p-1"
                    width={40}
                    src={search}
                    alt="Search"
                    onClick={() => {
                        handleOpenModal();
                    }}
                />
            </div>
        </>
    );
}

export default SearchComponent