import { React, useState } from 'react';
import floor from "./../assets/icons/floor.svg";
import profile from "./../assets/icons/profile.svg";
import map from "./../assets/icons/map.svg";
import schedule from "./../assets/icons/schedule.svg";
import logo from "./../logo.svg";

import { Link } from 'react-router-dom';
import SearchComponent from "./SearchComponent";
import SideBarComponent from "./SideBarComponent";

const Header = ({ moveMap, centerElement }) => {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-feri fixed-top">
                <SideBarComponent />
                <img draggable="false"
                    src={logo}
                    alt="Logo"
                    className="mx-auto d-block img-fluid"
                    style={{ maxWidth: "100px" }}
                />
                <SearchComponent centerElement={centerElement} moveMap={moveMap} />
            </nav>
        </>
    );
}

const TabsNav = () => {
    const [selectedTab, setSelectedTab] = useState('/');
    return (
        <>
            <nav className="navbar fixed-bottom navbar-light bg-feri">
                <div className="container justify-content-center">
                    <ul className="nav nav-tabs border-0 w-100 justify-content-around">
                        <li className="nav-item">
                            <Link to="/" className="nav-link rounded-0 vertical-center"
                                style={selectedTab === '/' ? { opacity: 1, transform: "scale(1.1)" } : { opacity: 0.75 }}
                                onClick={() => setSelectedTab('/')}>
                                <img draggable="false" src={map} alt="Map" />
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/floor" className="nav-link rounded-0 vertical-center"
                                style={selectedTab === '/floor' ? { opacity: 1, transform: "scale(1.1)" } : { opacity: 0.75 }}
                                onClick={() => setSelectedTab('/floor')}>
                                <img draggable="false" src={floor} alt="Floor" />
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/schedule" className="nav-link rounded-0 vertical-center"
                                style={selectedTab === '/schedule' ? { opacity: 1, transform: "scale(1.1)" } : { opacity: 0.75 }}
                                onClick={() => setSelectedTab('/schedule')}>
                                <img draggable="false" src={schedule} alt="Schedule" />
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/profile" className="nav-link rounded-0 vertical-center vertical-center"
                                style={selectedTab === '/profile' ? { opacity: 1, transform: "scale(1.1)" } : { opacity: 0.75 }}
                                onClick={() => setSelectedTab('/profile')}>
                                <img draggable="false" src={profile} alt="Profile" />
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Header;


export { TabsNav, Header };