import React, { useState } from "react";

import MapService from "../services/MapService";

const HomePage = ({ neededMap }) => {
  return (
    <>
      <div className="vertical-center">
        <div className="container-fluid px-0">
          <MapService key={neededMap} loadMap={neededMap} />
        </div>
      </div>
    </>
  );
};

export default HomePage;
