import sidebar from "./../assets/icons/sidebar.svg";
import { createRoot } from 'react-dom/client';
import { useEffect, useState, useCallback, useRef } from 'react';

const SideBarComponent = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isUnmountingRef = useRef(false);

  const handleSidebarToggle = useCallback(() => {
    setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
  }, []);

  useEffect(() => {
    const container = document.createElement("div");
    document.getElementById("root").appendChild(container);

    const root = createRoot(container);

    const handleOverlayClick = () => {
      setIsSidebarOpen(false);
    };

    root.render(
      <>
        {isSidebarOpen && (
          <div className="overlay overlay-sidebar" onClick={handleOverlayClick} />
        )}
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          {/* Sidebar Content Goes Here */}
          <ul>
            <li>Sidebar</li>
            <li>Menu Content</li>
            <li>Lives here...</li>
          </ul>
        </div>
      </>
    );

    // Set the flag to indicate unmounting process
    isUnmountingRef.current = false;

    return () => {
      // Delay the unmounting process until the next render cycle
      if (!isUnmountingRef.current) {
        isUnmountingRef.current = true;
        root.unmount();
        container.remove();
      }
    };
  }, [isSidebarOpen]);

  return (
    <div className="toggle sidebar-wrapper position-absolute l-0 px-4" onClick={handleSidebarToggle}>
      <img draggable="false" id="sidebar-btn" src={sidebar} alt="Sidebar" className={`trans-3 header-btn p-1 ${isSidebarOpen ? "toggled" : ""}`} />
    </div>
  );
};

export default SideBarComponent;
