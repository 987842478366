import floorImg from './../assets/icons/floor-1.svg';

import React, { useEffect, useState } from 'react';
import APIService from '../services/APIService';
import { useNavigate } from 'react-router-dom';

const FloorPage = ({ moveMap }) => {
  const [floors, setFloorsData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFloors = async () => {
      if(localStorage.getItem("floors")) setFloorsData(JSON.parse(localStorage.getItem("floors")));
      else {
        try {
          let response = await APIService.get('/floors');
          localStorage.setItem("floors", JSON.stringify(response));
          setFloorsData(response);
        } catch (error) {
          console.log('Error:', error);
        }
      }
    };

    fetchFloors();
  }, []);

  function viewFloor(floor) {
    moveMap(floor);
    navigate('/');
  }

  return (
    <>
      <div className="vertical-center">
        <div className="container floor-container fw-bold text-feri mx-md-auto">
            {floors.reverse().map((floor, index) => (
              <p
                className="floor-text d-flex m-0 justify-content-between"
                key={index}
              >
              <span onClick={() => { viewFloor(floor.split('.')[0]) }} className="h2">{ floor.split('.')[0] }</span> <img draggable="false" alt={floor.split('.')[0]} style={{ zIndex: floor.split('.')[0][0]+1, opacity: (1 - (floors.length - index)/10) }} className="floor-img"
              src={floorImg} onClick={() => { viewFloor(floor.split('.')[0]) }} />
              </p>
            ))}
        </div>
      </div>
    </>
  );
};

export default FloorPage;