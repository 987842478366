import TranslationContext from './context/TranslationContext';

class TranslateService {
  constructor() {
    this.selectedLanguage = 'en';
    this.translations = {};
  }

  async fetchTranslations(lang) {
    const response = await fetch(`/lang/${lang}.json`); // Fetch the translation file from the public folder
    this.translations = await response.json(); // Parse the JSON response
    this.selectedLanguage = lang;
  }

  lang(key) {
    return (
      <TranslationContext.Consumer>
        {(translations) => translations[key] || key}
      </TranslationContext.Consumer>
    );
  }

  getSelectedLanguage() {
    return this.selectedLanguage;
  }
}

export default TranslateService;
