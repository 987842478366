import avatar from "./../assets/icons/avatar.svg";
import { useEffect, useState } from "react";
import APIService from '../services/APIService';
import CardComponent from "../components/CardComponent";
import { useRef } from "react";
import { CButton } from "@coreui/react";
import { CToaster } from '@coreui/react'
import AccordionComponent from "../components/AccordionComponent";
import ModalComponent from "./../components/ModalComponent";
import { createRoot } from 'react-dom/client';
import ToastComponent from "../components/ToastComponent";

function isAuthenticated() {
  return localStorage.getItem("profile") ? true : false;
}

function isValidEmail(email) {
  // Regular expression pattern for email validation
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailPattern.test(email);
}

async function handleAuthentication(event, isRegister, changeForm, addToast) {
  event.preventDefault();

  const nameInput = document.getElementById("name");
  const emailInput = document.getElementById("email");
  const passwordInput = document.getElementById("password");

  const user = {
    name: nameInput?.value,
    email: emailInput?.value,
    password: passwordInput?.value,
  };

  if (isRegister) {
    if (
      !isAuthenticated() &&
      isValidEmail(user.email) &&
      user.password.length > 0
    ) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      };

      try {
        const data = await APIService.post('/register', requestOptions);
        if (data.status) {
          localStorage.setItem("profile", data.user.body);
          changeForm("profile");
        } else addToast(ToastComponent("Notification", new Date(Date.now()).toLocaleTimeString("SI-si"), data.message));
      } catch (error) {
        addToast(ToastComponent("Error", new Date(Date.now()).toLocaleTimeString("SI-si"), error.message));
      }
    } else if (user.email.length === 0 || user.password.length === 0) {
      addToast(ToastComponent("Warning", new Date(Date.now()).toLocaleTimeString("SI-si"), "Please input correct details!"));
    } else if (!isValidEmail(user.email)) {
      addToast(ToastComponent("Warning", new Date(Date.now()).toLocaleTimeString("SI-si"), "Please input a valid email"));
    } else {
      addToast(ToastComponent("Warning", new Date(Date.now()).toLocaleTimeString("SI-si"), "User already registered!"));
    }
  } else {
    if (
      !isAuthenticated() &&
      isValidEmail(user.email) &&
      user.password.length > 0
    ) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      };

      try {
        const data = await APIService.post('/login', requestOptions);
        if (data.status) {
          localStorage.setItem("profile", JSON.stringify(data.user));
          changeForm("profile");
        } else addToast(ToastComponent("Warning", new Date(Date.now()).toLocaleTimeString("SI-si"), data.message));
      } catch (error) {
        addToast(ToastComponent("Error", new Date(Date.now()).toLocaleTimeString("SI-si"), error.message));
      }
    } else if (user.email.length === 0 || user.password.length === 0) {
      addToast(ToastComponent("Warning", new Date(Date.now()).toLocaleTimeString("SI-si"), "Please input correct data!"));
    } else if (!isValidEmail(user.email)) {
      addToast(ToastComponent("Warning", new Date(Date.now()).toLocaleTimeString("SI-si"), "Please input a valid email"));
    } else {
      addToast(ToastComponent("Warning", new Date(Date.now()).toLocaleTimeString("SI-si"), (<p className="m-0">User already logged in! <span onClick={() => {
        localStorage.removeItem("profile");
        changeForm("logout");
      }} className="logout btn btn-outline-dark rounded-0 p-1">Log out</span></p>)));
    }
  }
}
// This shows the Login Form, and handles the click events. TODO: For now its working with demo content, change that.

const LoginForm = ({ changeForm, addToast }) => {
  return (
    <>
      <div className="container">
        <h2>Login</h2>
        <form
          onSubmit={(event) => {
            handleAuthentication(event, false, changeForm, addToast);
          }}
        >
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              className="form-control rounded-0"
              id="email"
              placeholder="Enter email"
              autoComplete="email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control rounded-0"
              id="password"
              placeholder="Password"
              autoComplete="current-password"
            />
          </div>
          <button type="submit" className="btn btn-dark rounded-0 mt-2">
            Login
          </button>
          <button
            onClick={(event) => {
              event.preventDefault();
              changeForm("register");
            }}
            className="btn btn-outline-dark rounded-0 mt-2 float-end"
          >
            Don't have an account?
          </button>
        </form>
      </div>
    </>
  );
};

// This shows the Register Form, and handles the click events. TODO: For now its working with demo content, change that.
const RegisterForm = ({ changeForm, addToast }) => {
  return (
    <>
      <div className="container">
        <h2>Register</h2>
        <form
          onSubmit={(event) => {
            handleAuthentication(event, true, changeForm, addToast);
          }}
        >
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control rounded-0"
              id="name"
              placeholder="Enter name"
              autoComplete="username"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              className="form-control rounded-0"
              id="email"
              placeholder="Enter email"
              autoComplete="email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control rounded-0"
              id="password"
              placeholder="Password"
              autoComplete="current-password"
            />
          </div>
          <button type="submit" className="btn btn-dark rounded-0 mt-2">
            Register
          </button>
          <button
            onClick={(event) => {
              event.preventDefault();
              changeForm("login");
            }}
            className="btn btn-outline-dark rounded-0 mt-2 float-end"
          >
            Already have an account?
          </button>
        </form>
      </div>
    </>
  );
};

const SelectField = ({ label, options, id, multiple, value }) => {
  value = value.replaceAll("{", "[");
  value = value.replaceAll("}", "]");
  return (
    <>
      <label htmlFor={id}>{label}:</label>
      <select onChange={() => {  }} value={multiple ? JSON.parse(value) : value} id={id} className="form-select rounded-0" multiple={multiple ? 1 : 0}>
        {options.map((option) => (
          <option value={option} key={option}>{option}</option>
        ))}
      </select>
    </>
  );
};

const LoadProfile = ({ profile, image, changeForm, addToast }) => {
  const studyProgramOptions = ["IPT", "RIT", "MK", "ET"];
  const studyYearOptions = [1, 2, 3];
  const studyGroupOptions = [1, 2, 3, 4, 5];

  return (
    <div className="col-12 col-md-6 my-3">
      <CardComponent title="Your Profile">
        <div className="text-center">
          <img draggable="false"
            className="mt-2 profile-image"
            src={image}
            alt="Profile"
            style={{ height: "10vh", width: "10vh" }}
          />
        </div>
        <h5 className="card-title">{profile.name}</h5>
        <p className="card-text">{profile.email}</p>
        <div className="student-data border-top">
          <SelectField label="Study Program" options={studyProgramOptions} id="program" value={profile.program} />
          <SelectField label="Study Year" options={studyYearOptions} id="year" value={profile.year} />
          <SelectField
            label="Study Groups"
            options={studyGroupOptions}
            id="group"
            multiple="true"
            value={profile.group}
          />
        </div>
        <CButton
          onClick={async () => {
            const profile = JSON.parse(localStorage.getItem("profile"));

            profile.program = document.getElementById("program").value;
            profile.year = document.getElementById("year").value;
            profile.group = Array.from(document.getElementById('group').selectedOptions).map(option => option.value);

            const requestOptions = {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(profile),
            };

            try {
              const data = await APIService.put('/update-user', requestOptions);
              if (data.status) localStorage.setItem("profile", JSON.stringify(data.user));
              addToast(ToastComponent("Success", new Date(Date.now()).toLocaleTimeString("SI-si"), data.message));
            } catch (error) {
              addToast(ToastComponent("Error", new Date(Date.now()).toLocaleTimeString("SI-si"), error.message));
            }
          }}
          className="btn btn-dark rounded-0 m-2"
        >
          Update Profile
        </CButton>
        <button
          onClick={() => {
            changeForm("logout");
          }}
          className="btn btn-outline-dark rounded-0 m-2"
        >
          Logout
        </button>
      </CardComponent>
    </div>

  );
};

const AdminPanel = ({ addToast }) => {
  const [users, setUsers] = useState([]);
  const [userQuery, setUserQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchUsers = async () => {
    try {
      const users = await APIService.get('users');
      setUsers(users);
    } catch (error) {
      console.log('Error:', error);
    }
  };
  
  const programs = [
    { program: "IPT", years: 3, groups: 5 },
    { program: "RIT", years: 3, groups: 5 },
    { program: "MT", years: 3, groups: 5 },
    { program: "TK", years: 3, groups: 5 },
    { program: "ME", years: 3, groups: 5 },
    { program: "ET", years: 3, groups: 5 }
  ];

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleUserQueryChange = (event) => {
    setUserQuery(event.target.value);
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setUserQuery(user.name);
  };

  const handleApplyChanges = async () => {
    if (selectedUser) {
      try {
        const updatedUser = await APIService.put(`/users/${selectedUser.id}`, selectedUser);
        if (updatedUser) fetchUsers();
      } catch (error) { addToast(ToastComponent("Error", new Date(Date.now()).toLocaleTimeString("SI-si"), error.message)); }
    }
  };

  const handleDeleteUser = async () => {
    if (selectedUser) {
      try {
        const response = await APIService.delete(`/users/${selectedUser.id}`);
        if (response) {
          addToast(ToastComponent("Notification", new Date(Date.now()).toLocaleTimeString("SI-si"), response.message));
          fetchUsers();
        }
      } catch (error) { addToast(ToastComponent("Error", new Date(Date.now()).toLocaleTimeString("SI-si"), error.message)); }
    }
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(userQuery.toLowerCase())
  );

  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div className="col-12 col-md-6 my-3">
      <CardComponent title="Admin Panel">
        <div className="accordion" id="accordionExample">
          <AccordionComponent
            title="Manage User Data"
            activeAccordion={activeAccordion}
            index={0}
            onAccordionClick={handleAccordionClick}
          >
            <div className="admin-data border-top">
              <label htmlFor="userEntryField">Enter username:</label>
              <input
                type="text"
                id="userEntryField"
                className="form-control rounded-0 mb-1"
                placeholder="Start typing a username..."
                value={userQuery}
                onChange={handleUserQueryChange}
              />

              {filteredUsers.length > 0 && (
                <ul className="user-dropdown list-group rounded-0">
                  {filteredUsers.slice(0, 5).map((user) => (
                    <li
                      key={user.id}
                      className="user-option list-group-item list-group-item-action"
                      onClick={() => handleUserSelect(user)}
                    >
                      {user.name}
                    </li>
                  ))}
                </ul>
              )}

              {selectedUser && (
                <>
                  <div className="form-group">
                    <label htmlFor="editedNameField">Name:</label>
                    <input
                      type="text"
                      id="editedNameField"
                      className="form-control rounded-0"
                      value={selectedUser.name}
                      onChange={(event) => {
                        setSelectedUser({ ...selectedUser, name: event.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="editedEmailField">Email:</label>
                    <input
                      type="email"
                      id="editedEmailField"
                      className="form-control rounded-0"
                      value={selectedUser.email}
                      onChange={(event) => {
                        setSelectedUser({ ...selectedUser, email: event.target.value });
                      }}
                    />
                  </div>
                  <button className="btn btn-dark rounded-0 m-2" onClick={() => {
                    handleApplyChanges();
                    setSelectedUser(null);
                  }}>
                    Apply Changes
                  </button>
                  <button className="btn btn-outline-dark rounded-0 m-2" onClick={() => {
                    setSelectedUser(null);
                  }}>
                    Cancel
                  </button>
                  <button onClick={() => {
                    handleDeleteUser();
                    setSelectedUser(null);
                  }} className="btn btn-outline-danger rounded-0 m-2">
                    Delete User
                  </button>
                </>
              )}
            </div>
            {/* Add more fields and admin functionalities as needed */}
          </AccordionComponent>
          <AccordionComponent
            title="Manage Study Data"
            activeAccordion={activeAccordion}
            index={1}
            onAccordionClick={handleAccordionClick}
          >
            <>
              <div className="form-group mb-1">
                <label htmlFor="editedNameField">Study Program:</label>
                <div className="row mx-auto">
                  <input
                    type="text"
                    id="editedNameField"
                    className="col form-control rounded-0"
                  />
                  <button className="col-lg-3 col-4 text-nowrap h-100 btn btn-dark rounded-0" onClick={() => {
                    console.log("Add study group");
                  }}>
                    Add
                  </button>
                </div>
              </div>
              <div className="programs">
              {programs.map((study) => (
                <div className="program row mx-auto">
                  <input
                    type="text"
                    id="editedNameField"
                    className="col form-control rounded-0"
                    value={study.program}
                    disabled
                  />
                  <button className="col-3 text-nowrap h-100 btn btn-outline-dark rounded-0" onClick={() => {
                    const container = document.createElement("div");
                    document.body.appendChild(container);

                    const root = createRoot(container);

                    root.render(
                      <ModalComponent setClass={"modal popup w-auto"} title="Study Program" isOpen={true} onClose={() => {
                        root.unmount();
                        container.remove();
                      }}>
                      <div className="heading">{study.program}</div>
                      <div className="content">
                      <div className="form-group mb-1">
                          <label htmlFor="editedNameField">Program Years:</label>
                          <div className="row mx-auto">
                            <input
                              type="text"
                              id="editedNameField"
                              placeholder={study.years ? study.years : "Unset..."}
                              className="col form-control rounded-0"
                            />
                            <button className="col-lg-3 col-4 text-nowrap h-100 btn btn-dark rounded-0" onClick={() => {
                              console.log("Add study group");
                            }}>
                              Set
                            </button>
                          </div>
                        </div>
                        <div className="form-group mb-1">
                          <label htmlFor="editedNameField">Program Groups:</label>
                          <div className="row mx-auto">
                            <input
                              type="text"
                              id="editedNameField"
                              placeholder={study.groups ? study.groups : "Unset..."}
                              className="col form-control rounded-0"
                            />
                            <button className="col-lg-3 col-4 text-nowrap h-100 btn btn-dark rounded-0" onClick={() => {
                              console.log("Add study group");
                            }}>
                              Set
                            </button>
                          </div>
                        </div>
                      </div>
                      </ModalComponent>
                    );
                  }}>
                    Modify
                  </button>
                  <button className="col-3 text-nowrap h-100 btn btn-outline-danger rounded-0" onClick={() => {
                    console.log("Add study group");
                  }}>
                    Delete
                  </button>
                </div>
                  ))}
              </div>
            </>
          </AccordionComponent>
          <AccordionComponent
            title="Manage App Data"
            activeAccordion={activeAccordion}
            index={2}
            onAccordionClick={handleAccordionClick}
          >
            <p>Content for Managing App Data</p>
          </AccordionComponent>
        </div>
      </CardComponent>
    </div>
  );
};

const ProfilePage = () => {
  const [refresh, setRefresh] = useState(false);
  const [toast, addToast] = useState(0);
  const toaster = useRef();

  /**
   * profileState - integer variable
   * 0 - register state
   * 1 - login state
   * 2 - profile state
   * 3 - error state
   */

  const [profileState, setProfileState] = useState(() => {
    const storedProfileState = localStorage.getItem("profileState");
    return storedProfileState ? parseInt(storedProfileState, 10) : 0;
  });

  const changeForm = (name) => {
    switch (name) {
      case 'register':
        setProfileState(0);
        break;
      case 'login':
        setProfileState(1);
        break;
      case 'logout':
        localStorage.removeItem('profile');
        setProfileState(1);
        break;
      case 'profile':
        setProfileState(2);
        break;
      default:
        if (isAuthenticated()) setProfileState(2);
        else setProfileState(3);
        break;
    }
    localStorage.setItem("profileState", profileState);
    setRefresh(true);
  };

  // Perform the action when 'refresh' state is changed
  useEffect(() => {
    if (refresh) {
      // Reset 'refresh' state to false
      setRefresh(false);
    }
  }, [refresh]);

  const profileData = () => {
    return JSON.parse(localStorage.getItem("profile"));
  };

  if (isAuthenticated() && !profileState) setProfileState(2);

  return (
    <>
      <div className="vertical-center">
        {Number(profileState) === 0 ? (
          <RegisterForm changeForm={changeForm} addToast={addToast} />
        ) : Number(profileState) === 1 ? (
          <LoginForm changeForm={changeForm} addToast={addToast} />
        ) : Number(profileState) === 2 ? (
          <>
            <div className="row mx-0 w-100">
              <LoadProfile
                profile={profileData()}
                image={avatar}
                changeForm={changeForm}
                addToast={addToast}
              />
              {profileData().role === "admin" ? (<AdminPanel changeForm={changeForm} addToast={addToast} />) : ("")}
            </div>
          </>
        ) : (
          <div className="text-center">
            <p>An error has occured... Please contact support.</p>
          </div>
        )
        }
        <CToaster ref={toaster} push={toast} placement="top-end" />
      </div>
    </>
  );
};

export default ProfilePage;
