import React, { useState } from 'react';

const AccordionComponent = ({ title, children, activeAccordion, index, onAccordionClick }) => {
    const isActive = activeAccordion === index;

    const toggleAccordion = () => {
        onAccordionClick(index);
    };

    return (
        <div className="accordion-item rounded-0">
            <h2 className={`accordion-header ${isActive ? 'active' : ''}`} onClick={toggleAccordion} >
                <button className="accordion-button rounded-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded={isActive}>
                    {title}
                </button>
            </h2>
            {isActive &&
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        {children}
                    </div>
                </div>}
        </div>
    );
};

export default AccordionComponent;
