import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';

import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';

import { Header, TabsNav } from './components/GlobalComponents';

import WelcomePage from './pages/WelcomePage';
import ProfilePage from './pages/ProfilePage';
import HomePage from './pages/HomePage';
import SchedulePage from './pages/SchedulePage';
import CalendarPage from './pages/CalendarPage';
import FloorPage from './pages/FloorPage';
import { useContext, useEffect, useRef, useState } from 'react';
import { CToaster } from '@coreui/react';

import TranslationContext from './services/context/TranslationContext';
import TranslateService from './services/TranslateService';
import ToastComponent from './components/ToastComponent';

const App = () => {
  const [translateService] = useState(new TranslateService());
  const [translations, setTranslations] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  useEffect(() => {
    fetchTranslations(selectedLanguage);
  }, [selectedLanguage]);

  const fetchTranslations = async (lang) => {
    await translateService.fetchTranslations(lang);
    setTranslations(translateService.translations);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
  };

  return (<Router><UI translations={translations} /><>
  <TranslationContext.Provider value={translateService}>
    <div className="language-selector position-absolute right-05 top-5">
      <select value={selectedLanguage} onChange={handleLanguageChange}>
        <option value="en">EN</option>
        <option value="slo">SL</option>
        <option value="bih">SR-HR</option>
        <option value="mk">MKD</option>
      </select>
    </div>
    </TranslationContext.Provider>
  </></Router>);
};

const UI = (translations) => {
  // eslint-disable-next-line no-unused-vars
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  const handleReload = (state) => {
    if (isFirstTime) localStorage.setItem('hasOpenedBefore', true);
    setReload(prevReload => !prevReload);
    if (state !== undefined) {
      localStorage.setItem("profileState", state);
      navigate('profile');
    } else localStorage.setItem("profileState", 0);
  };


  const isFirstTime = !localStorage.getItem('hasOpenedBefore');

  const [neededMap, setNeededMap] = useState(
    localStorage.getItem("currentMap") ? localStorage.getItem("currentMap") : "0N"
  );

  if (!neededMap) setNeededMap("0N");

  const moveMap = (map) => {
    localStorage.setItem("currentMap", map);
    setNeededMap(map);
  };

  const centerElement = () => {
    const transformComponent = document.getElementsByClassName("react-transform-component")[0];
    const centerElement = document.getElementById('center-element');

    if (transformComponent && centerElement) {
      const wrapperRect = transformComponent.getBoundingClientRect();
      const elementRect = centerElement.getBoundingClientRect();

      const scrollLeft = elementRect.left - wrapperRect.left - wrapperRect.width / 2 + elementRect.width / 2;
      const scrollTop = elementRect.top - wrapperRect.top - wrapperRect.height / 2 + elementRect.height / 2;  

      transformComponent.style.transform = `translate(${scrollLeft}px, ${scrollTop}px) scale(1.5)`;
    }
  };

  return (
    <>
      {isFirstTime ? '' : <Header centerElement={centerElement} moveMap={moveMap} />}
      <Routes>
        <Route path="/" element={isFirstTime ? <WelcomePage handleReload={handleReload} /> : <HomePage neededMap={neededMap} />} />
        <Route path="/floor" element={isFirstTime ? <WelcomePage handleReload={handleReload} /> : <FloorPage moveMap={moveMap} />} />
        <Route path="/profile" element={isFirstTime ? <WelcomePage handleReload={handleReload} /> : <ProfilePage />} />
        <Route path="/schedule" element={isFirstTime ? <WelcomePage handleReload={handleReload} /> : <SchedulePage />} />
        <Route path="/schedule/:forDate" element={isFirstTime ? <WelcomePage handleReload={handleReload} /> : <SchedulePage />} />
        <Route path="/calendar" element={isFirstTime ? <WelcomePage handleReload={handleReload} /> : <CalendarPage />} />
        {/* Other routes go here */}
      </Routes>
      {isFirstTime ? '' : <TabsNav />}
    </>
  );
}

export default App;