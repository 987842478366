import React from 'react';

const Modal = ({ isOpen, onClose, setClass, children }) => {
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      {isOpen && (
        <div id="overlay" className="overlay" onClick={handleOverlayClick}>
          <div className={setClass}>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
