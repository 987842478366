import React, { useState } from 'react';
import './../../src/App.css';
import { useNavigate } from 'react-router-dom';

const CalendarPage = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const navigate = useNavigate();

    const handleDateSelect = (date) => {
        setSelectedDate(date);
    };

    const handleMonthChange = (event) => {
        const selectedMonth = parseInt(event.target.value);
        setCurrentDate((prevDate) => {
            return new Date(prevDate.getFullYear(), selectedMonth);
        });
    };

    const generateDatesForMonth = () => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();

        const numDaysInMonth = new Date(year, month + 1, 0).getDate();
        const dates = [];

        for (let i = 1; i <= numDaysInMonth; i++) {
            const date = new Date(year, month, i);
            dates.push(date);
        }

        return {
            year,
            month: currentDate.toLocaleString('en-US', { month: 'long' }),
            dates,
        };
    };

    const { year, month, dates } = generateDatesForMonth();

    return (
        <>
            <div className="vertical-center">
                <div className="calendar-page">
                    <h1 className="calendar-title">
                        {month} {year}
                    </h1>
                    <div className="calendar-controls">
                        <div className="form-group d-flex">
                            <select className="form-select rounded-0" onChange={handleMonthChange} value={currentDate.getMonth()}>
                                {Array.from({ length: 12 }, (_, index) => (
                                    <option key={index} value={index}>
                                        {new Date(currentDate.getFullYear(), index).toLocaleString('en-US', { month: 'long' })}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="calendar">
                        {dates.map((date) => (
                            <div
                                key={date.getTime()}
                                className={`date ${selectedDate && selectedDate.getTime() === date.getTime() ? 'selected' : ''}`}
                                onClick={() => handleDateSelect(date)}
                            >
                                {date.getDate()}
                            </div>
                        ))}
                    </div>
                    {selectedDate && (
                        <div className="selected-date">
                            <h2>{selectedDate.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</h2>
                            <p>Load schedule for selected date</p>
                            <button className="btn btn-dark rounded-0 mt-2" onClick={() => { navigate('/schedule/'+selectedDate.toLocaleDateString('en-US').replaceAll('/', '.')); }}>Load Schedule</button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default CalendarPage;
