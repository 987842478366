import React, { useEffect, useState } from 'react';
import '../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import APIService from '../services/APIService';

const ScheduleComponent = () => {
  const [scheduleData, setScheduleData] = useState([]);
  const [scheduleDate, setScheduleDate] = useState('');
  const navigate = useNavigate();
  const { forDate } = useParams();

  useEffect(() => {
    const fetchScheduleData = async () => {
      try {
        let response
        if (forDate) {
          response = await APIService.get(`/schedules/date/${forDate}`); //response = await fetch(`https://feriapi.cikez.net/schedules/date/${forDate}`);
        } else {
          response = await APIService.get('/schedules');
        }
        const data = response;
        setScheduleData(data);
      } catch (error) {
        console.log('Error:', error);
      }
    };

    fetchScheduleData();
  }, []);

  useEffect(() => {
    if(!forDate) {
      const currentDate = new Date().toLocaleDateString('en-US');
      setScheduleDate(currentDate);
    } else setScheduleDate(forDate.replaceAll('.', '/'));
  }, []);

  const prettyDate = (dateTime) => {
    const time = dateTime.slice(-6); // Extract the last 6 characters representing the time
    const formattedTime = `${time.slice(0, 2)}:${time.slice(2, 4)}`; // Format as HH:mm:ss
    return formattedTime;
  };

  return (
    <>
      <div className="vertical-center">
        <div className="container content-container">
          <div className="border-feri d-flex justify-content-between mb-3">
          <p className="schedule-date m-auto">
            Schedule Date: <span>{scheduleDate}</span>
          </p>
          <button className="btn btn-dark rounded-0" onClick={() => {navigate('/calendar')}}>Change Date</button>
          </div>
          {scheduleData.map((entry) => (
            <div key={entry.UID} className="schedule-entry">
              <div className="class-name">{entry.SUMMARY}</div>
              <div className="location">{entry.LOCATION}</div>
              <div className="time">{prettyDate(entry.DTSTART)} - {prettyDate(entry.DTEND)}</div>
              <div className="professor">{entry.DESCRIPTION}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ScheduleComponent;