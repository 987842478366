import axios from 'axios';
import { toast } from 'react-toastify';

// Configuring axios defaults (optional)
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = "https://feriapi.cikez.net";

// API service class
class APIService {
  // GET request
  static async get(url) {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      this.handleRequestError(error);
      throw error;
    }
  }

  // POST request
  static async post(url, data) {
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      this.handleRequestError(error);
      throw error;
    }
  }

  // PUT request
  static async put(url, data) {
    try {
      const response = await axios.put(url, data);
      return response.data;
    } catch (error) {
      this.handleRequestError(error);
      throw error;
    }
  }

  // PATCH request
  static async patch(url, data) {
    try {
      const response = await axios.patch(url, data);
      return response.data;
    } catch (error) {
      this.handleRequestError(error);
      throw error;
    }
  }

  // DELETE request
  static async delete(url) {
    try {
      const response = await axios.delete(url);
      return response.data;
    } catch (error) {
      this.handleRequestError(error);
      throw error;
    }
  }

  // Handle request errors
  static handleRequestError(error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      const { status, data } = error.response;
      toast.error(`Request failed with status ${status}: ${data.message}`);
    } else if (error.request) {
      // The request was made but no response was received
      toast.error('No response from the server');
    } else {
      // Something happened in setting up the request that triggered an error
      toast.error('An error occurred while making the request');
    }
  }
}

export default APIService;
