import React, { useEffect, useReducer, useState } from "react";
import ReactDOM from 'react-dom/client';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import APIService from "./APIService";
import ModalComponent from "./../components/ModalComponent";
import schedule from "./../assets/icons/floor-schedule.svg";
import { useNavigate } from "react-router";
import { CToaster } from "@coreui/react";
import ToastComponent from "../components/ToastComponent";

const MapService = (loadMap) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [toast, addToast] = useState(0);
    const toaster = useReducer();

    const fetchScheduleData = async (classroom) => {
        try {
            const data = await APIService.get('/schedules/room/' + classroom);
            return data;
        } catch (error) {
            console.log('Error:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await APIService.get("/maps");
                setData(data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };

        if (!localStorage.getItem("navigation")) fetchData();
        else setData(JSON.parse(localStorage.getItem("navigation")));
    }, []);
    
    useEffect(() => {
        const setPoint = async (pozX, pozY, conf) => {
            // Get the SVG element
            var elements = document.querySelectorAll('.user-location');

            elements.forEach(function(element) {
                element.remove();
            });
            const wrapper = document.getElementById('map-wrapper');
            console.log(wrapper.children[0]);
            const svg = wrapper.children[0];

            // Create a circle element
            const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");

            // Set the circle attributes (position, size, color, etc.)
            const x = pozX; // X-coordinate
            const y = pozY; // Y-coordinate
            const radius = conf; // Circle radius
            const color = "blue"; // Circle color

            circle.classList.add("user-location");
            circle.setAttribute("cx", x);
            circle.setAttribute("cy", y);
            circle.setAttribute("r", radius);
            circle.setAttribute("fill", color);
            console.log("Added circle");
            // Append the circle to the SVG
            svg.appendChild(circle);
        }

        const root = document.getElementById('root');
        root.setAttribute("DEVICE_ID", 123);
        // const DEVICE_ID = root.getAttribute("DEVICE_ID");
        const DEVICE_ID = 564564;
        let delay = 5000;
        if(DEVICE_ID) {
          addToast(ToastComponent("Welcome to FERINavigate!", new Date(Date.now()).toLocaleTimeString("SI-si"), "Have a great day!"))
          setTimeout(() => {
            setInterval(async () => {
                try {
                    const data = await APIService.get("/location/"+DEVICE_ID);
                    console.log(data);
                    setPoint(646, 924, 10);
                    delay -= 1000;
                } catch (error) {
                    delay += 1000;
                }
            }, delay);
          }, 3000);
        }
      }, []);

    const prettyDate = (dateTime) => {
        const time = dateTime.slice(-6); // Extract the last 6 characters representing the time
        const formattedTime = `${time.slice(0, 2)}:${time.slice(2, 4)}`; // Format as HH:mm:ss
        return formattedTime;
    };

    const setListeners = () => {
        const data = JSON.parse(localStorage.getItem("navigation"));
        const selectedView = JSON.parse(localStorage.getItem("selectedView"));

        data.data
            .filter((data) => data.floor === selectedView.name)
            .filter((data) => !data.name.toLowerCase().includes(("STOPNICE").toLowerCase()))
            .filter((data) => !data.name.toLowerCase().includes(("ELEVATOR").toLowerCase()))
            .filter((data) => !data.name.toLowerCase().includes(("KABINET").toLowerCase()))
            .filter((data) => !data.name.toLowerCase().includes(("00").toLowerCase()))
            .filter((data) => !data.name.toLowerCase().includes(("G2-N2-03").toLowerCase()))
            .filter((data) => !data.name.toLowerCase().includes(("KAVARNA").toLowerCase()))
            .filter((data) => !data.name.toLowerCase().includes(("REFERAT").toLowerCase()))
            .filter((data) => !data.name.toLowerCase().includes(("SENATNA").toLowerCase()))
            .forEach((result) => {

                const element = document.getElementById(result.id);

                // Dont add listeners multiple times
                if (element.getAttribute('listener') === 'true') return;

                element.addEventListener("click", async () => {
                    if (document.getElementById('load-btn').getAttribute("listeners-active") !== 'true') return;

                    const scheduleEntries = await fetchScheduleData(result.name).then(async (scheduleData) => {
                        return scheduleData && scheduleData
                            .slice()
                            .sort((a, b) => new Date(a.DTSTART).getTime() - new Date(b.DTSTART).getTime())
                            .map((schedule) => (
                                <div className="schedule-entry" key={schedule.id}>
                                    <p className="class-name">{schedule?.SUMMARY}</p>
                                    <p className="location">{schedule?.LOCATION}</p>
                                    <p className="time">
                                        {prettyDate(schedule?.DTSTART)} - {prettyDate(schedule?.DTEND)}
                                    </p>
                                    <p className="professor">{schedule?.DESCRIPTION}</p>
                                </div>
                            ));
                    });

                    const container = document.createElement("div");
                    document.body.appendChild(container);

                    const popupContent = <h6 className="title">{result.name}</h6>;


                    const modalContent = (
                        <>
                            <div className="heading">{popupContent}</div>
                            <div className="entries border-top-feri">{scheduleEntries && scheduleEntries.length > 0 ? scheduleEntries :
                                <>
                                    <p className="font-italic my-5">
                                        {localStorage.getItem("profile") ?
                                            <i>There is nothing today for this classroom.</i> :
                                            <i>Please <b onClick={() => {
                                                navigate('profile');
                                                document.getElementById("overlay").click();
                                            }}>login</b> to see the schedule of this classroom.</i>}

                                    </p>
                                </>
                            }</div>
                        </>
                    );

                    const root = ReactDOM.createRoot(container);

                    root.render(
                        <ModalComponent
                            setClass="modal popup"
                            isOpen={true}
                            onClose={() => {
                                root.unmount();
                                container.remove();
                            }}
                        >
                            {modalContent}
                        </ModalComponent>
                    );
                });
                element.setAttribute('listener', 'true');
            });
    };


    if (isLoading) {
        return <div className="text-center"><p>Loading Maps...</p></div>;
    }

    let selectedView;

    if (data) {
        localStorage.setItem("navigation", JSON.stringify(data));
        selectedView = data.maps.find((map) => map.name === loadMap.loadMap);
        localStorage.setItem("selectedView", JSON.stringify(selectedView));
        return (
            <>
                <div className="map-container position-relative">
                    <TransformWrapper
                        options={{
                            limitToBounds: false,
                            transformEnabled: true,
                            wheel: { step: 50 },
                        }}
                    >
                        <TransformComponent>
                            <div
                                id="map-wrapper"
                                className="map-wrapper"
                                dangerouslySetInnerHTML={{ __html: selectedView.content }}
                            ></div>
                        </TransformComponent>
                    </TransformWrapper>
                    <p className="map-info m-0 position-absolute z-top bg-white top-5 left-05 border-feri overflow-hidden">
                        <span id="info-text" className="p-2 align-middle">
                            {selectedView.name[1] === "N" ? "Floor" : "Medetage"} {selectedView.name[0]}
                        </span> <u
                            id="load-btn"
                            className="btn btn-dark rounded-0 p-1"
                            onClick={() => {
                                ;
                                const btn = document.getElementById("load-btn");
                                if (btn.classList.contains("btn-dark")) {
                                    btn.classList.remove("btn-dark");
                                    btn.classList.add("btn-white");
                                    btn.children.item(0).classList.add("invert-1");
                                    setListeners();
                                    btn.setAttribute("listeners-active", true);
                                } else {
                                    btn.classList.remove("btn-white");
                                    btn.classList.add("btn-dark");
                                    btn.children.item(0).classList.remove("invert-1");
                                    btn.setAttribute("listeners-active", false);
                                }
                            }
                            }
                        >
                            <img draggable="false" src={schedule} alt="Show Floor Schedule" />
                        </u>
                    </p>
                </div>
                <CToaster ref={toaster} push={toast} placement="top-end" />
            </>
        );
    }

    return <div className="text-center"><p>An error has occurred, please contact support.</p></div>;
};

export default MapService;