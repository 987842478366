import logo from './../logo.svg';
import { React, useState } from 'react';

const TextCarousel = ({ content, handleReload }) => {
  const [currentSlideIndex, setcurrentSlideIndex] = useState(0);

  const nextText = () => {
    setcurrentSlideIndex((prevIndex) =>
      prevIndex === content.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="text-carousel">
      <p className="text" dangerouslySetInnerHTML={{ __html: content[currentSlideIndex] }}></p>
      {currentSlideIndex === content.length - 1 ? (
        <div className="btn-wrapper d-block">
          <button className="btn btn-dark rounded-0 my-2 w-100" onClick={() => { handleReload.handleReload() }}>Continue as Guest</button>
          <button className="btn btn-dark rounded-0 my-2 w-100" onClick={() => { handleReload.handleReload(0) }}>Register Account</button>
          <button className="btn btn-dark rounded-0 my-2 w-100" onClick={() => { handleReload.handleReload(1) }}>Already Registered?</button>
        </div>
      ) : (
        <button className="btn btn-dark rounded-0" onClick={nextText}>Next</button>
      )}
    </div>
  );
};

const WelcomePage = (handleReload) => {
  const carouselSlides = [
    `<i>This application has been developed with ❤️ to ease your way around the FERI Faculty.</i>`,
    'You can use this app with or without an account, but we recommend creating an account for optimal experience!',
    'Thank you for your support!'
  ];

  return (
    <section className="section welcome text-center text-white bg-feri">
      <div className="vertical-center">
        <div className="container">
          <img draggable="false" width={250} src={logo} alt="FERINavigate Logo" />
          <h1 className="heading-text text fw-bold my-5">Welcome to FERINavigate!</h1>
          <TextCarousel className="my-5" content={carouselSlides} handleReload={handleReload} />
        </div>
      </div>
    </section>
  );
};

export default WelcomePage;