import { CToast, CToastBody, CToastHeader } from "@coreui/react";

const ToastComponent = (title, time, content) => (
    <CToast className="rounded-0">
        <CToastHeader closeButton>
            <svg
                className="rounded-0 me-2"
                width="20"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                role="img"
            >
                <rect width="100%" height="100%" fill={
                    title.toLowerCase().includes("error") ? "#dc3545" :
                        title.toLowerCase().includes("warning") ? "#ffc107" :
                            title.toLowerCase().includes("notification") ? "#005f85" :
                                title.toLowerCase().includes("success") ||
                                    title.toLowerCase().includes("update") ? "#28a745" : "#007aff"}></rect>
            </svg>
            <div className="fw-bold me-auto">{title}</div>
            <small>{time}</small>
        </CToastHeader>
        <CToastBody>{content}</CToastBody>
    </CToast>
);

export default ToastComponent;


